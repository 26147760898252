.block-document {
    max-width: $tablet;
    @include touch {
        margin-inline: 1rem;
    }
    @extend .has-text-black; 
    @extend .content; 

    a {
        color: $black;
        text-decoration: none !important;

        &:hover {
            text-decoration: underline !important;
        }
    }
}