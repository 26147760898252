.pagination {
    justify-content: center;
    .pagination-link {
        border: 0;
        margin:0;
        padding: 0;
    }
    .pagination-next{
        border: 0;
        color: $blue-dark;
    }
    .pagination-previous {
        border: 0;
        color: $blue-dark;
    }
    .pagination-link.is-current {
        background-color: $white;
        color: $blue-dark;
    }
    .pagination-previous[disabled], .pagination-next[disabled] {
        background-color: $white;
    }
    ul.pagination-list {
        flex-shrink: 0;
        flex-grow: 0;
    }
}