.block-suggested_link {
    // Notificacion
    $notification-border: 0.5rem;
    $list-notification-height: 8rem;
    $grid-notification-height: 10rem;
    $background-item: var(--color);
    $opacity-office: 0.3;

    // Titulo
    $title-display: inline-block;
    $title-weight: bold;
    $title-line-height: 2rem;
    $title-border: 0.3rem;
    $title-padding: 1.5%;
    $grid-title-color: $grey-darker;
    $title-background-color: $white-bis;
    $title-width: 90%;

    // Overlay
    $overlay-top: 1.875rem;
    $overlay-opacity:0.99;
    $overlay-padding: 1em;
    $overlay-margin: 0;

    // Shadow
    $shadow-color: rgba(0, 0, 0, 0.25);

    // Variables
    $size-text: 1.25rem;
    $text-color-office: var(--color);
    $text-color: $white;
    $icon-padding: 2rem;

    @mixin title-suggested {
        font-size: $size-text;
        font-weight: $title-weight;
        line-height: $title-line-height;
        display: $title-display;
    }

    .shadow {
        box-shadow: 0 3px 8px $shadow-color;

        @media (hover: hover) {
            &:hover {
                box-shadow: 0 7px 15px $shadow-color;
                transition: all 50ms ease-in;
            }
        }
    }

    .notification {
        background-color: $background-item;
        border-radius: $notification-border;
    }

    .list-item {
        .is-office {
            .notification {
                opacity: $opacity-office
            }
            .icon-text {
                color: $text-color-office;
            }
            .title-item {
                color: $text-color-office;
            }
        }

        .no-office {
            .icon-text {
                color: $text-color;
            }
            .title-item {
                color: $text-color;
            }
        }

        .notification {
            min-height: $list-notification-height;
        }

        .overlay-list {
            @include overlay(0);
            border-radius: $notification-border;
            padding: $overlay-padding;
            margin: $overlay-margin;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;
        }

        .icon-text {
            font-size: $size-text;
            padding-left: $icon-padding;
        }

        .title-item {
            @include title-suggested;
        }
    }

    .grid-link {
        .image {
            position: initial;

            img {
                opacity: .75;
            }
        }

        .notification {
            height: $grid-notification-height;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .image-link {
            height: $grid-notification-height;
            border-radius: $notification-border;
            object-fit: cover;
        }

        .overlay-grid {
            opacity: $overlay-opacity;
            display:flex;
            flex-direction: column;
            align-items: center;
        }

        .title-grid {
            @include title-suggested;
            color: $white;
            max-width: $title-width;
            padding: $title-padding;
            border-radius: $title-border;
            text-align: center;
        }
    }
}
