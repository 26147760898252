.accordions {
    .accordion {
        .message-header {
            border-radius: 4px;
            cursor: pointer;
        }

        .accordion-header-icon {
            transition: transform 200ms ease-out;
            display: flex;
        }

        .accordion-content {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: max-height 100ms ease;
        }

        &.active {
            .message-header {
                border-radius: 4px 4px 0 0;
            }

            .accordion-header-icon {
                transform: rotate(-180deg);
                transition: transform 100ms ease-in;
            }
            
            .accordion-content {
                max-height: 30rem;
                opacity: 1;
                overflow-y: auto;
                transition: max-height 100ms ease;
                padding: 1rem;
                padding-top: 2rem;
            }
        }

        .richtext-image {
            height: auto;
            display: block;
    
            @include tablet {
                width: 50%;
                max-width: 50%;
            }
    
            &.left {
                float: left;
    
                @include tablet {
                    padding: 0.3rem 1rem 0 0;
                }
                @include desktop {
                    padding: 0.3rem 2rem 0 0;
                }
            }
    
            &.right {
                float: right;
    
                @include tablet {
                    padding: 0.3rem 0 0 1rem;
                }
            
                @include desktop {
                    padding: 0.3rem 0 0 2rem;
                }
            }
        
            &.center {
                max-width: 70%;
                padding: 1rem 0;
                margin: auto;
            }
        
            &.full-width {
                padding: 1rem 0;
                max-width: 100%;
                width: fit-content;
                width: -moz-available;
                width: -webkit-fill-available;
            }
        }

        .block-document {
            margin-block: 32px;
        }

    }
}

// block-accordion
.block-accordion {
    .accordion-header {
        h3 {
            color: $white !important;
            margin-bottom: 0 !important;
        }
    }
}

.accordions-container-related [class*="block-"],
.accordions-container-suggested [class*="block-"],
.accordions-container-video_list [class*="block-"],
.block-video_list {
    
    .box {
        background-color: transparent;
        padding: 0;
        overflow: hidden;
        height: 100%;

        &:hover {
            transform: translateY(-$offset-y);
            box-shadow: $shadow-hover;
            transition: all 0.25s ease;
        }

        iframe {
            display: block;
        }
        
        .box-content {
            align-items: baseline;
            text-align: left;
            padding: .75rem;

            .content {
                .title {
                    color: $black;
                    font-size: 1.25rem !important;
                    margin-bottom: 1rem;
                }
                p {
                    color: $black;
                    font-weight: normal;
                    font-size: .875rem;
                }
            }
        }
    }

    .is-full {
        .box {
            @include tablet {
                display: grid; 
                grid-template-columns: 1fr 2fr; 
                grid-template-rows: auto; 
                grid-gap: 10px;
                grid-template-areas: ". . ."; 
            }

            figure {
                grid-row: 1;
            }
            
            .box-content {
                text-align: left;
                padding: .75rem;
            }
        }
    }
}

.accordions-container-related,
.accordions-container-suggested,
.accordions-container-video_list {

    [class*="block-"] {
        margin-block: 0;
    }
}

.block-suggested_link,
.block-related_content,
.block-video_list {
    .accordion {
        margin-bottom: 0 !important;

        .accordion-header {
            h3 {
                margin-bottom: 0 !important;
            }
        }
        .message-header {
            justify-content: left;
            background-color: transparent !important;
            color: $black;
            padding:.75rem 0;
        }

        .accordion-content {
            padding: 0 !important;
        }

        &.active {
            .accordion-header-icon {
                transform: rotate(-135deg);
            }

            .accordion-content {
                overflow: visible;
                max-height: 3000vh;
                padding-bottom: 1rem !important;
            }
        }
    }
    .column {
        &.list-item,
        &.grid-link {
            margin-bottom: 0 !important;
        }
    }
    .buttons {
        padding-top: 1rem !important;
    }
}