.block-slider {
    max-width: none !important;
    width: 100%;
    color: $white;

    .glide__slide {
        height: auto;
        @extend .has-background-blue-light;

        &.glide__slide {
            @extend .border-top-left-radius;

            &.is-full {
                border-radius: 0;
            }
        }

        &.overlay {
            position: relative;

            picture {
                &:after {
                    content: "";
                    background: $blue;
                    opacity: 0.6;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }

    h3 {
        font-weight: 700;
        font-size: 24px;
        text-shadow: $shadow;
    }
    
    h4 {
        font-weight: 600;
        font-size: 20px;
        text-shadow: $shadow;
    }

    p {
        padding-top: 0.5rem;
        text-shadow: $shadow;
    }

    .slider-half-text {
        height: 100%;
        margin: 0;
        align-items: center;
        justify-content: flex-end;

        @include desktop {
            width: 50vw;
        }

        @include touch {
            display: flex;
            justify-content: center;
        }

        .column {
            padding: 2rem;
            @extend .is-two-thirds-mobile;
            @extend .is-two-thirds-tablet;
            @extend .is-three-quarters-desktop;
            @extend .is-two-thirds-widescreen;
            @extend .is-half-fullhd;
        }

        h3, h4 {
            color: $blue-dark;
        }

        .button-see-more {
            text-align: end;

            a {
                color: $white;
            }

            &:hover {
                a {
                    color: $blue-dark;
                }
            }
        }
    }

    .slider-full-text {
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;

        @include touch {
            display: flex;
            justify-content: center;
        }

        .column {
            text-align: center;
            @extend .is-two-thirds-mobile;
            @extend .is-two-thirds-tablet;
            @extend .is-half-desktop;
            @extend .is-half-widescreen;
            @extend .is-half-fullhd;
        }
    }

    .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .is-half {
        .image {
            display: none;
            width: 50%;
        
            @include desktop {
                display: block;
            } 
        }
    }
    
    .is-full {
        .image {
            width: 100%;
            left: 0;
        }

        a {
            color: $blue-dark;
            margin: 0.5rem;

            @extend .button;
        }
    }
}

.border-top-left-radius {
    overflow: hidden;

    @include tablet {
        border-radius: 15rem 0 0 0; 
        // animation: top-left-radius 2s 1;

        @keyframes top-left-radius {
            0% {
                border-radius: 0;
            }
            50% {
                border-radius: 15rem 0 0 0;
            }
        }
    }
}

.glide__arrows {
    @extend .container;
    @extend .is-max-desktop;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -1px;

    .glide__arrow {
        box-shadow: none;
        border: none;

        &.glide__arrow--left {
            left: 0;
            padding: 9px 1rem;
            
            @include desktop {
                padding: 9px 12px;
                left: -$gap * 2;
            }
        }

        &.glide__arrow--right {
            right: 0;
            padding: 9px 1rem;
            
            @include desktop {
                padding: 9px 12px;
                right: -$gap * 2;
            }
        }
    }
}