.finder {
    display: block;

    &.is-active {
        display: block;
    }

    $py-menu: 8rem;
    $px-menu: 6rem;
    $pt-icon: 3rem;
    $input-radius: $radius-rounded;
    $finder-radius: 16rem;
    $finder-color: $blue-dark;
    $size-3: 2rem;
    $size-5: 1.25rem;

    .icon {
        color: $white;
    }

    .dropdown-menu {
        min-width: 100%;
        padding-top: 0;
        position: fixed;
        top: 0;
        z-index: 22;
        background-color: $finder-color;
        border-bottom-right-radius: $finder-radius;

        //revisar
        bottom: 4rem;
        overflow-y: scroll;
    }

    .container-menu {
        .icon-close {
            cursor: pointer;
            color: findColorInvert($finder-color);
            position: absolute;
            top: $pt-icon;
            right: $px-menu;
            @include mobile {
                right: $size-3;
            }
        }

        .container-search {
            padding: $py-menu $px-menu;
            @include mobile {
                padding: $py-menu $size-3;
            }

            input {
                padding-inline-start: calc(#{$control-padding-horizontal} + #{$size-6});
                &::placeholder {
                    color: $grey-darker;
                }
            }
        }

        .block-section {
            padding-top: $py-menu / 2;
        }

        .block-text {
            margin-top: 0;
            a {
                font-weight: $weight-bold;
                padding-inline-start: $size-3;
                line-height: 2;
                color: findColorInvert($finder-color);
                span:hover {
                    background-color: findLightColor($finder-color);
                    color: $finder-color;
                }
                @include mobile {
                    padding-inline-start: 0;
                }
            }
        }

        .text-title {
            font-size: $size-5;
            padding-block-end: $size-6;
            color: findColorInvert($finder-color);
        }
    }
}

.suggested_links {

    $radius-large: 8px;
    $padding-text: 0.25rem;

    .column {
        padding: .5rem;
    }

    li {
        position: relative;
    }

    .overlay-filter {
        @include overlay(0);
        border-radius: $radius-large;

        &.overlay-text {
            color: $white;
            font-weight: 600;
            font-size: $size-5;
            padding: $padding-text;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            @include mobile {
                font-size: 0.95rem;
            }
        }
    }

    figure {
        background-color: $blue-light;
        border-radius: $radius-large;

        & img {
            border-radius: $radius-large;
            filter: grayscale(100%) brightness(0.8) contrast(120%);
            mix-blend-mode: multiply;
        }
    }
}
