.block-menu {
    
    .menu-item {

        .menu-content{
            position: relative;
            box-shadow: $shadow;
            border-radius: 1rem;

            &:hover {
                transform: translateY(-$offset-y);
                box-shadow: $shadow-hover;
                transition: all 0.25s ease;
            }

            figure{
                border-radius: 1rem;
                overflow: hidden;

                &.overlay {
        
                    &:after {
                        content: "";
                        background: $blue;
                        background-blend-mode: multiply;
                        opacity: 0.4;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }
            }

            p {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                font-weight: 600;
                font-size: 1.3rem;
                color: $white;
                text-align: center;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
            }         
        }
    }
}
