.block-paragraph {
    max-width: $tablet;
    clear: both;
    
    @extend .has-text-black; 
    @extend .content; 
    @extend .is-medium; 

    @include touch  {
        margin-inline: 1rem;
    }

    * {
        clear: both;
    }

    a {
        overflow-wrap: break-word;
    }
    
    .richtext-image {
        height: auto;
        display: block;
        margin-bottom: 1rem;
    
        @include tablet {
            width: 50%;
            max-width: 50%;
        }
    
        &.left {
            
            @include tablet {
                float: left;
                clear: initial;
                margin-right: 1rem;
            }
        }
    
        &.right {
            
            @include tablet {
                float: right;
                clear: initial;
                margin-left: 1rem;
            }
        }
    
        &.center {
            max-width: 70%;
            margin: auto;
        }
    
        &.full-width {
            max-width: 100%;
            width: fit-content;
            width: -moz-available;
            width: -webkit-fill-available;
        }
    }

    .richtext-image.left + p {

        @include tablet {
            clear: initial;
            float: right;
            width: calc(50% - 1rem);
        }
    }
    
    .text-left {

        @include tablet {
            clear: initial;
            float: left;
            width: calc(50% - 1rem);
        }
    }
    
}
