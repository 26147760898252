.block-related_content {
    $size-5: 1.25rem;
    $box-width: 100%;
    $box-height: 8rem;
    $box-background-color: hsla(207, 100%, 27%, 0.5);
    $box-color: $white;
    $row-gap: 2rem;

    .box {
        width: $box-width;
        height: $box-height;
        background-color: $box-background-color;
        position: relative;
        box-shadow: $shadow;
        &.center-content {
            display: flex;
            place-content: center center;
        }
        &:hover {
            transform: translateY(-$offset-y);
            box-shadow: $shadow-hover;
            transition: all 0.25s ease;
        }
    }

    .box-content {
        align-items: center;
        text-align: center;
        font-size: $size-5;
        font-weight: $weight-bold;
        color: $box-color;
        display: flex;
        position: relative;
        z-index: 1;
    }

    .box-overlay {
        @include overlay(0);
        background-color: $box-background-color;
        border-radius: $radius-large;
    }
}