.home {
  .block-slider:first-of-type {
    padding-top: 0rem;
    margin-top: 0rem;


    .glide__slide {
      border-radius: 0;
      &.is-halfheight{
        min-height: 70vh;
      }

      &.glide__slide--active {
        border-radius: 0;
      }
    }
  }
}
