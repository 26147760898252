
.template-404, .template-500 {
    div.content {
        height: 250px;
        h1, h2 {
            color: $black !important;
        }
    }
    section.container {
        padding-top: 10rem;
    }

    #is-header-hero {
        min-height: 300px;
        max-height: 300px;
    }

    .menu-footer {
        visibility: hidden;
    }
}