.block-related_news,
.block-news,
.result-list {
    $list-box-shadow: 0.5em 0.5em 1em -0.25em rgba(25, 25, 26, 0.1), 0 0px 0 0px rgba(25, 25, 26, 0.02);
    $list-img-max-width: 388px;
    $list-img-max-height: 256px;
    $box-max-width: 428px;
    $border-radius: 10px;
    $card-image-max-height: 276px;
    $card-img-max-height: 256px;
    $card-max-width: 320px;
    $card-header-height: 4rem;
    $card-content-height: 5rem;
    
    @include touch {
        margin-inline: 1rem;
    }
    
    .buttons:last-child {
        margin-bottom: 0.5rem;
    }

    .title-promotion {
        color: $blue-dark;
        font-size: $size-2;
        font-weight: 700;
        line-height: 2.5rem;
        padding-bottom: 1rem;
    }

    .mobile-aligner {
        @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        }
    }

    .list-layout {
        img {
            border-radius: $border-radius;
            @include tablet {
                margin-right: 1rem;
            }
            max-width: $list-img-max-width;
            max-height: $list-img-max-height;
        }

        .box {
            box-shadow: $list-box-shadow;
            padding: 0;
            margin-block: $gap;
            @include mobile {
                max-width: $box-max-width;
                width: 100%;
                box-shadow: none;
            }

            h3 {
                @include tablet {
                margin-bottom: 1.75rem;
                padding-left: 1rem;
                }
                @include mobile {
                    padding-top: 1rem;
                }
            }

            p {
                @include tablet {
                    padding-left: 1rem;
                }
            }
        }

        .columns {
            margin-left: 0;
            margin-right: 0;
        }

        .column:first-child {
            padding: 0;
        }

        @include mobile {
            .columns:last-child {
                margin-bottom: 0;
            }
        }
    }

    .grid-layout {
        /* Actualizar con variable global de border radius y elemento tag  */
        .tag {
            color: $white;
            border-radius: 0 8px 8px 0 !important;
            letter-spacing: 5px;
            font-weight: 700;
            position: absolute;
            z-index: 1;
            top: 0.75rem;
        }

        .card {
            padding-bottom: 1rem;
            @include mobile {
                max-width: $card-max-width;
            }
        }

        .card-header {
            padding-top: 1rem;
            padding-bottom: 0.3rem;
            @include tablet {
                align-items: center;
            }
            @extend .is-shadowless;
        }

        .card-image {
            max-height: $card-image-max-height;
            img {
                border-radius: $border-radius;
                max-width: $card-max-width;
                max-height: $card-img-max-height;
            }
        }

        .card-content {
            padding: 0;
        }
    }
}

.result-list {
    .grid-layout {
        @include mobile {
            margin-left: 0;
            margin-right: 0;
        }

        .card-image {
            img {
                max-width: none;
                max-height: none;
            }
        }
    }
}