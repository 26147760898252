.result-list {
    padding: $gap 0 0;
    @extend .container; 
    @extend .is-max-desktop;
}

.title-header {
    font-size: 2.813rem;
    font-weight: 900;
    line-height: 4.063rem;
    @include mobile {
        line-height: 1.875rem;
    }
}

section.no-results {
    min-height: 350px;
}

 /* El resto de los estilos está en _block-news_block... etc porque usa grid layout  */