[class*="block-"] {
    margin-top: $gap*2;
    margin-bottom: $gap*2;
    @extend .container; 
    @extend .is-max-desktop;

    @include touch {
        margin-inline: $gap / 2;
    }
}

[class*="block-"]:first-child {
    margin-top: 0;
}

[class*="block-"]:last-child {
    margin-bottom: 0;
}

// Bloques que sobreescriben Bulma
.block-columns:not(:last-child),
.block-paragraph:not(:last-child),
.block-document:not(:last-child),
.block-quote:not(:last-child) {
    margin-bottom: $gap*2;
}

// Bloques de ancho completo de la página
.block-banner,
.block-gallery,
.block-slider {
    margin-inline: 0;
}

// Bloques que están dentro de otro bloque
.block-authority {
    margin-top: 0;
    margin-bottom: 0;
}

.home :nth-child(1 of .block-slider) {
    margin-top: 0;
}