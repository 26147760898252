.block-banner {
    max-width: none !important;
    width: 100%;
    
    .overlay::after {
        height: 100%;
    }
    
    .container {
        align-self: center;

        .legend {
            position: relative;
            min-height: 252px;
            margin: 0;
            background-color: hsla(207, 100%, 27%, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile {
                padding: $gap / 2;
                margin-right: 0;
                margin-left: 0;
            }

            h3 {
                color: $white;
                line-height: 3.5rem;
                font-size: 2.5rem;
            }

            h4 {
                color: $white;
                line-height: 2.75rem;
                font-size: 1.75rem;
            }

            a {
                color: $blue-dark;
                margin: 0.5rem;

                @extend .button;
            }
        }

        .image {
            height: 100%;
            width: 100%;
            position: absolute;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .box-banner1 {
        max-width: 960px;
        margin: $gap*2 auto;

        .container {

            .legend {
                position: relative;
                min-height: 252px;

                @include mobile {
                    padding: $gap / 2;
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .box-banner2 {
        max-width: 960px;
        margin: $gap*2 auto;

        .container {
            min-width: 100%;
            min-width: -moz-available;
            min-width: -webkit-fill-available;
            min-width: fill-available;
            min-height: 26rem;

            .legend {
                min-height: 26rem;
            }
        }

        .is-not-clickable {
            cursor: default;
        }
    }

    .box-banner3 {

        .container {
            min-width: 100%;
            min-width: -moz-available;
            min-width: -webkit-fill-available;
            min-width: fill-available;
            min-height: 26rem;

            .legend {
                min-height: 26rem;
            }
        }

        .is-not-clickable {
            cursor: default;
        }
    }
}