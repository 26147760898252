// Generales
@import "general/fonts";
@import "general/variables";

// Librerías
@import "~bulma";
@import "~bulma-tooltip";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

@import "node_modules/buefy/src/scss/buefy";

// Animations
@import "general/animations";

// Parciales
@import "partials/burger";
@import "partials/finder";
@import "partials/menu";
@import "partials/office-menu";
@import "partials/header-hero";
@import "partials/footer";
@import "partials/office";
@import "partials/result-list";
@import "partials/pages-section";
@import "partials/pagination";
@import "partials/home";
@import "partials/tags";
@import "partials/404_500";

// Components
@import "components/navbar";
@import "components/block-title";
@import "components/block-column";
@import "components/block-paragraph";
@import "components/accordion";
@import "components/block-banner";
@import "components/block-authority";
@import "components/block-document";
@import "components/blocks";
@import "components/office-color";
@import "components/block-networks";
@import "components/block-slider";
@import "components/block-image";
@import "components/block-quote";
@import "components/block-news_block-related_news";
@import "components/block-menu";
@import "components/block-gallery";
@import "components/block-related_content";
@import "components/block-suggested";
@import "components/block-selected_events";
@import "components/block-embed";

//Social Networks
@import "general/social-networks";

* {
    overflow-wrap: break-word;
}

// Fixes
body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    text-rendering: optimizelegibility\9;
  
}

