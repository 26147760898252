.navbar {

    .navbar-item {
        @include desktop {
            .navbar-dropdown-first {
                .dropdown-style {
                    max-width: 960px;
                    width: 960px;
    
                }
            }
        }
    }

    .app-rotate-arrow {
        .app-trigger {
            svg {
                transform: rotate(-180deg);
            }
        }
    }
    
    .app-dropdown {
        display: none;
        max-height: 0;
        overflow: hidden;
    
        &.app-show-menu {
            display: block;
            max-height: 1000px;
            overflow: visible;
        }
    }

    @include touch {
        #dropdown-menu.is-active {
            display: flex;
            opacity: 1;
        }
    
        #app-navbar-menu.is-active {
            display: block;
            opacity: 1;
        }

        .app-nav-layout {
            display: none;
            opacity: 0;
        }
    
        .navbar-menu {
            display: block;
        }
    }

    @include desktop {
        #dropdown-menu.is-active {
            display: flex;
            opacity: 1;
        }
    }
}
