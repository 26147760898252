#is-header-hero {
    // Titulo variables
    $title-color: var(--color);
    $title-line-heigh: 1.875rem;

    // Descripcion variables
    $description-size: 2.813rem;
    $description-weight: 900;
    $description-line-heigh: 4.063rem;

    &.overlay {
        position: relative;

        &:after {
            content: "";
            background: var(--color) 60%;
            background-blend-mode: multiply;
            opacity: 0.3;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .title-hero {
        background-color: $white;
        display: inline-block;
        text-transform: uppercase;
        font-size: 1.25rem;
        font-weight: 900;
        letter-spacing: 0.3em;
        line-height: $title-line-heigh;
        margin-bottom: 0.375rem;
        padding-inline: 0.75rem;
    }

    .title-office {
        color: $title-color;
    }

    .description {
        color: $white;
        font-size: $description-size;
        font-weight: $description-weight;
        line-height: $description-line-heigh;

        @include mobile {
            line-height: $title-line-heigh;
        }
    }
}

.office {
    #is-header-hero {
        &.is-halfheight {
            min-height: 70vh;

            @include mobile {
                min-height: 100vh;
            }
        }
    }
}