.block-default-events,
.block-events,
.block-selected_events {

    $box-radius: 8px;
    $box-color: $white-bis;
    $box-shadow-grid: 3px 3px 3px rgba(0, 0, 0, 0.1);
    $box-shadow-list: 2px 2px 2px rgba(0, 0, 0, 0.16);
    $size-3: 2rem;
    $size-6: 1rem;
    $height-img-grid: 8.5rem;
    $width-img-list: 200px;
    $height-img-list: 330px;
    $font-size-7: 14px;

    .box {
        display: flex;
        border-radius: $box-radius;
        position: relative;
    }

    .box-title {
        color: $blue-dark;
    }

    img {
        border-radius: $box-radius;
    }

    .box-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
    }

    .icon-text {
        align-items: center;
    }

    .grid-layout {

        .is-hidden-grid {
            display: none !important;
        }

        .box {
            padding: 0;
            height: $height-img-grid;
            box-shadow: $box-shadow-grid;
        }

        .image {
            @include touch {
                display: none;
            }
        }
        

        .image img {
            height: $height-img-grid;
            @include mobile {
                width: $height-img-grid;
                height: $height-img-grid;
                object-fit: cover;
            }
        }

        .box-content {
            padding-inline-start: $size-3;
            padding-inline-end: $size-7;
            &.no-image {
                padding: $size-6;
                padding-inline-start: $size-3;
            }

            .box-title {
                line-height: 1;
            }

            .date, .clock, .place {
                font-size: $font-size-7;
                color: $grey-darker;
            }

            .date {
                margin-right: $size-3;
            }

            .icon-text.place {
                display: block;
            }
        }
    }

    .list-layout {

        .box {
            flex-direction: row-reverse;
            justify-content: space-between;
            background-color: $box-color;
            box-shadow: $box-shadow-list;
        }

        .image {
            @include tablet-only {
                width: 50%;
            }
        }

        /* img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            @include tablet {
                width: $width-img-list * 2;
                height: $height-img-list;
            }
        } */

        .box-content {
            padding-inline-end: $size-6;
            @include mobile {
                padding-inline-end: 0;

                .place {
                    font-size: $font-size-7;
                }
            }

            .box-header {
                display: flex;
                flex-direction: column;
                place-content: flex-start space-between;
                flex: 1;
                margin-block-start: 4rem;
            }

            .box-title {
                padding-block-end: $size-6;
                @include mobile {
                    padding-block-end: 0;
                    padding-block-start: $size-6;
                }
            }
        }

        .box-date {
            position: absolute;
            top: $box-padding;
            left: 0;
            display: flex;
            place-content: center;
            .date {
                @extend .tag;
                color: $white;
                margin-inline-end: $size-6;
            }
        }

        .place_link {
            display: flex;
        }

        .box-button.icon-text {
            display: flex;
            align-self: flex-end;
        }

        @include mobile {
            .box {
                flex-direction: column;

                .box-date {
                    top: 2rem;
                }
            }

            .box-content:not(.no-image) {
                .box-header {
                    margin-block-start: 0;
                }

                .box-date {
                    top: 0.5rem;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .date {
                        margin-left: -1.25rem;
                    }
                }

                .clock {
                    display: block;
                    margin-block: $size-7;
                    padding-inline-start: $box-padding;
                }
            }

            .place_link,
            .box-description,
            .box-button.icon-text {
                display: none;
            }
        }
    }
}