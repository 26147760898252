.block-gallery {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.gallery-1 {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-top: $gap*2;
    margin-bottom: $gap*2;
}

.glide__arrows {
    left: 0;
    right: 0;
    top: 0;
    bottom: -1px;
    max-width: 850px;

    .glide__arrow {
        box-shadow: none;
        border: none;
    }
}

.glide__bullets {
    bottom: 1rem;
}

.modal {
    $icon-position: 20px;

    .glide__download {
        height: $icon-position;
        position: fixed;
        left: $icon-position;
        top: $icon-position;
        color: $white;
    }

    figcaption {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-background {
        background-color: $black;
    }
    .modal-content {
        width: 70%;
    }
}