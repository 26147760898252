.navbar {
    $mobile-menu-width: 70%;
    $size-1: 3rem;
    $size-2: 2.5rem;
    $size-3: 2rem;
    $shadow: 0px 4px 8px rgba(0, 35, 79, 0.25);

    position: fixed;
    height: 3.75rem;
    @include desktop {
        height: 7.25rem;
    }

    & > div {
        position: fixed;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .container {
        @include touch {
            max-width: none !important;
            padding-left: $gap / 2;
            padding-right: $gap / 2;
            width: 100%;
        }
    }

    .navbar-brand {
        display: flex;
        align-items: center;
        @include touch {
            justify-content: space-between;
            width: 100%;
            .columns {
                margin-top: 0;
            }
            .columns:last-child,
            .buttons {
                margin-bottom: 0;
            }
        }

        .navbar-item {
            @include touch {
                padding-inline: 0;
            }
        }
    }

    .logo-start {
        padding: 0;
        display: flex;
        align-items: center;

        svg {
            width: 60px;
            @include touch {
                width: 40px;
                height: 40px;
            }
            path {
                fill: $white;
            }
        }
    }

    .logo-end {
        @include touch {
            display: none;
        }
        svg {
            width: 160px;
            path {
                fill: $white;
            }
        }
    }

    .search-button {
        margin-right: 0.5rem;
        .icon {
            @include fa($size-6, $size-1);
            border-radius: $radius-rounded;
            display: flex;
            flex-direction: column;
            width: 40px;
            height: 40px;
            @include mobile {
                font-size: 0.8rem;
            }
        }
    }

    .navbar-burger {
        width: 40px;
        height: 40px;
        border-radius: $radius-rounded;
        background-color: $white;
        color: var(--color);
        @include desktop {
            display: none;
        }

        span {
            height: 2px;
        }
    }

    .navbar-end {
        display: flex;
        .navbar-item {
            flex-direction: column;
            align-items: flex-start;
            flex-basis: min-content;
        }
    }
}
