$size-1: 1.875rem;
$size-2: 1.75rem;
$size-3: 1.5rem;
$size-4: 1.25rem;
$size-5: 1.15rem;
$size-7: 0.75rem;

$black:hsl(240, 2%, 10%);
$white: hsl(0, 0%, 100%);

$white-bis:hsl(210, 67%, 98%);
$grey: hsl(207, 6%, 69%);
$grey-light: hsl(207, 8%, 78%);
$grey-lighter: hsl(210, 19%, 88%);
$grey-dark: hsl(214, 3%, 59%);
$grey-darker:hsl(210, 2%, 49%);

$blue: hsl(207, 100%, 27%);
$cyan: hsl(206, 100%, 35%);
$blue-light: hsl(217, 49%, 66%);
$blue-dark:hsl(213, 100%, 15%);

$magenta: hsl(330, 81%, 49%);
$magenta-light: hsl(330, 58%, 77%);
$magenta-dark: hsl(330, 67%, 45%);

$orange: hsl(31, 92%, 54%);
$orange-light: hsl(30, 80%, 82%);
$orange-dark:hsl(30, 65%, 45%);

$green: hsl(93, 51%, 51%);
$green-light:hsl(107, 39%, 79%);
$green-dark:hsl(116, 39%, 44%);

$custom-apm: ("black": ($black, $white),
    "black-invert": ($white, $black),
    "white": ($white, $black),
    "white-invert": ($black, $white),

    "white-bis": ($white-bis, $black),
    "grey": ($grey, $white),
    "grey-light": ($grey-light, $black),
    "grey-lighter": ($grey-lighter, $black),
    "grey-dark": ($grey-dark, $white),
    "grey-darker": ($grey-darker, $white),

    "blue": ($blue, $white),
    "blue-invert": ($white, $blue),
    "cyan": ($cyan, $white),
    "blue-light": ($blue-light, $white),
    "blue-dark": ($blue-dark, $white),

    "magenta": ($magenta, $white),
    "magenta-invert": ($magenta),
    "magenta-light": ($magenta-light, $white),
    "magenta-dark": ($magenta-dark, $white),

    "orange": ($orange, $white),
    "orange-invert": ($white, $orange),
    "orange-light": ($orange-light, $white),
    "orange-dark": ($orange-dark, $white),

    "green": ($green, $white),
    "green-invert": ($white, $green),
    "green-light": ($green-light, $white),
    "green-dark":($green-dark, $white));

$custom-colors: $custom-apm;

$family-primary: 'Poppins', sans-serif;

//Title
$title-size: $size-2 !important;
$title-weight:900 !important;

.has-weight-black {
    font-weight: 900 !important;
}

//Breadcrumb
$breadcrumb-item-separator-color: $grey-dark !important;

//Block modifier
$block-spacing: 0rem;

$z-index-1: 1;

// Tags
$tag-radius: 0 8px 8px 0;
$tag-background-color: $blue-dark !default;
$tag-color: $white !default;

// Navbar Variables
$navbar-item-hover-background-color: transparent;
$navbar-box-shadow-color: $grey-light;

// Shadow
$shadow-color: hsla(0,0,0,0.25);
$shadow-blur: 0.625rem;
$offset-x: 0;
$offset-y: 0.25rem; //padding de cada columna
$shadow-blur-hover: $shadow-blur + $offset-y;
$offset-y-hover: $shadow-blur-hover + $offset-y;
$shadow: $offset-x $offset-y $shadow-blur $shadow-color;
$shadow-hover: $offset-x $offset-y-hover $shadow-blur-hover $shadow-color;

//footer
$footer-background-color: $blue-dark;
$footer-padding: 4rem 1.5rem 6rem;

//Estas son variables propias de tamaños para icons que conectan con _social-networks
//Modificar los preseteados no anduvo bien
$icon-size-small: 2rem;
$icon-size-medium: 2.5rem;

$icon-dimensions-medium: 1.875rem;

// Search input icon
$input-icon-color: $blue-dark;
$input-icon-active-color: $blue-dark;

@function diffuse-shadows( $size, $color: rgba(0, 0, 0, 0.125), $multiply: false) {
    // via https://codepen.io/marksdk/pen/wvBoNaj
    $val: 0 1px 1px $color;

    @for $i from 1 through $size {
        @if $i % 2==0 {
            @if $multiply==true {
                $i: $i * 2;
            }

            $val: #{$val},
            0 #{$i}px #{$i}px #{$color};
        }
    }

    @return $val;
}

@mixin diffuse($size, $color, $multiply) {
    box-shadow: diffuse-shadows($size, $color, $multiply);
}

@mixin diffuse-text($size, $color, $multiply) {
    text-shadow: diffuse-shadows($size, $color, $multiply);
}

#main-page-title,
#main-page-title + p {
    @include diffuse-text(16, rgba($grey-dark, 0.15), false);
}