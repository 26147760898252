.icon-medium {
    height: $icon-size-medium  !important;
    width: $icon-size-medium  !important;
}

.icon-small {
    height: $icon-size-small  !important;
    width: $icon-size-small  !important;
}

.social-network {
    background-repeat: no-repeat;
    background-size: cover;
}

.breadcrumb {
    row-gap: 1rem;
}