.navbar {
    .office-color {
        background-color: var(--color-office);

        a,
        .icon {
            color: $white;
        }
    }

    .item-text {
        font-size: 12px;
    }

    .navbar-dropdown {
        cursor: auto;
    }

    @include desktop {
        .navbar-menu {
            margin-right: 0 !important;
            height: 7.25rem;
        }
    }
}

// Menú general
.navbar-menu .office-menu {
    width: min-content;

    .navbar-item {
        padding: .5rem;

        .navbar-link {
            .icon-text {
                flex-wrap: nowrap;
                align-items: center;
                @include touch {
                    justify-content: space-between;
                }

                span {
                    color: $white;
                    @include touch {
                        color: var(--color-office);
                    }
                }
            }
        }
    }
}

@include touch {
    .navbar-menu {
        position: absolute;
        top: 3.75rem;
        right: 0;
        width: 100%;
        padding-top: 0;
        background-color: rgba(238, 238, 228, 0.3);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
        overflow: scroll;
        height: 100vh;

        a {
            color: var(--color-office);
        }

        .office-menu {
            background-color: $white;
            flex-direction: column;
            width: 100%;
            padding: 0.5rem 0;
        }
    }
}

// level-1 desktop
.office-menu {
    .office-title {
        p {
            color: $white;
            font-size: clamp(16px, 2.3vw, 30px);
            font-weight: $weight-bold;
            @include desktop {
                padding-right: $size-3;
                margin-right: $size-1;
            }
        }

        span {
            color: $white;
            line-height: $size-7;
            font-weight: $weight-semibold;
        }

        @include desktop {
            &:hover, &.fixed-title {
                cursor: pointer;
                background-color: var(--color-office);
                p {
                    color: $white;
                }
                span {
                    color: $white;
                }
            }
        }
    }

    @include desktop {
        .submenu-desktop {
            &.navbar-item {
                min-height: 7.25rem;
            }

            &:hover, &.fixed-submenu {
                .navbar-dropdown-first {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.navbar-dropdown {
                        padding-block: 0;
                        position: fixed;
                        top: 5.25rem;
                        padding-top: 2rem;
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                    }

                    .dropdown-style {
                        background-color: var(--color-office);
                        border-radius: $radius-large;
                        display: flex;
                        min-width: fit-content;
                        align-items: center;
                        justify-content: center;
                        padding-block: 0.5rem;
                    }

                    .icon-text .icon {
                        height: $size-6;
                    }

                    .navbar-dropdown-third {
                        position: relative;
                    }
                }
            }
        }

        .office-title:last-child {
            .navbar-dropdown.navbar-dropdown-first .dropdown-style {
                border-top-right-radius: 0;
            }
        }

        .submenu-mobile {
            display: none;
        }

        .submenu-desktop:hover {
            .navbar-dropdown-first {
                display: flex !important;
            }
        }
    }
}
.has-notification-area {
    .office-menu {
    
        @include desktop {
            .submenu-desktop {
    
                &:hover, &.fixed-submenu {
                    .navbar-dropdown-first {
                        
                        &.navbar-dropdown {
                            top: 8.55rem;
                        }
                    }
                }
            }
        }
    }
    &.has-hidden-notification-area {
        .office-menu {
        
            @include desktop {
                .submenu-desktop {
        
                    &:hover, &.fixed-submenu {
                        .navbar-dropdown-first {
                            
                            &.navbar-dropdown {
                                top: 5.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

// level-1 mobile
@include touch {
    .navbar-menu .office-menu {
        .office-title {
            padding-inline: 1.5rem;

            .navbar-item {
                padding-left: 0;
                padding-right: 0;
            }
            // links sin descendientes
            .solo-link .navbar-item {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;

                p {
                    font-size: 30px;
                    font-weight: 800;
                    color: var(--color-office)
                }

                span.description {
                    font-size: 12px;
                    font-weight: 500;
                    color: $blue-dark;
                }
            }
            // separador
            &:not(:last-child) {
                .submenu-mobile,
                .solo-link {
                    border-bottom: 1px solid $grey-lighter;
                }
            }
        }

        .submenu-desktop {
            display: none;
        }

        .navbar-item .navbar-link .icon-text.level_1 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            a {
                font-size: 30px;
                font-weight: 800;
            }

            span.description {
                font-size: 12px;
                font-weight: 500;
                color: $blue-dark;
            }

            span.icon {
                background-color: $white-bis;
                border-radius: 50%;
                justify-self: end;
                height: 40px;
                width: 40px;
            }
        }

        .dropdown-level-1 {
            padding-top: 1rem;
        }
    }
}

// level-2
.office-menu {

    .navbar-dropdown {
        a.navbar-item:focus,
        a.navbar-item:hover,
        a.navbar-item.is-active,
        a.is-active,
        a:hover,
        .is-active a {
            color: $white;
            text-decoration: underline;
            background-color: transparent;
        }
    }

    .navbar-dropdown-first {

        @include desktop {
            align-items: center;

            .navbar-item {
                padding: 0 1rem;
            }

            .navbar-link {
                padding: 0;
            }

            .dropdown-style {
                max-width: 960px;
                width: 960px;
            }
        }

        a {
            font-weight: $weight-bold;
            color: $white;
        }
    }
}

// level-2 mobile
@include touch {
    .submenu-mobile {
        &.navbar-item {
            position: static;

            .navbar-link {
                padding: 0;
            }
        }

        .navbar-dropdown {
            padding-block: 0;
        }

        .dropdown-style {
            padding-inline: 0.5rem;
            background-color: var(--color-office);
            .navbar-item .navbar-link .icon-text span {
                color: $white !important;
            }
        }
    }
}

// level-3 y level-4
.office-menu {
    @include touch {

        .navbar-dropdown-second {
            .navbar-item {
                padding-inline: 0;
            }
        }

        .navbar-dropdown-third div {
            padding-top: 0.35rem;
        }
    }

    @include desktop {
        .navbar-dropdown-second {
            &.navbar-dropdown {
                top: calc(100% + 0.3rem);
                padding-block-end: $size-6;
                background-color: transparent;
                border-top: none;
                box-shadow: none;
            }

            .office-item {
                border-radius: $radius;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                box-shadow: none;
                .navbar-dropdown {
                    border: none;
                }
            }

            .navbar-item {
                padding: 0.5rem 1rem;

                .navbar-dropdown-third {
                    padding-top: 0.35rem;
                }
            }
        }
    }
}

// Oficinas
@include desktop {
    .current-office {
        &.office-title {
            background-color: var(--color);

            &:hover, &.fixed-title  {
                background-color: $white;
                p, span {
                    color: var(--color);
                }
            }

            .dropdown-style,
            .dropdown-style .office-item {
                background-color: $white !important;
                a, span {
                    color: var(--color) !important;
                }
                a.navbar-item:focus,
                a.navbar-item:hover,
                a.navbar-item.is-active,
                a.is-active,
                a:hover,
                .is-active a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.finder .icon {
    &.search-trigger {
        color: var(--color);
        background-color: $white;
    }
}

body.home {
    .office-title {
        p {
            color: var(--color-office);
        }
        span {
            color: $blue-dark;
        }
    }

    .finder .icon.search-trigger {
        color: $blue;
        background-color: $white;
    }

    .navbar-burger {
        color: $blue-dark;
    }

    .app-scroll-style {
        .finder .icon.search-trigger {
            color: $white;
            background-color: $blue;
        }
    }
}
// Scroll
.app-scroll-style {
    @extend .is-fixed-top;
    .logo-start svg {
        path {
            fill: $blue-dark !important;
        }
    }

    .logo-end {
        path {
            fill: $blue-dark !important;
        }
    }

    .office-title {
        background-color: transparent;
            p, span {
                color: var(--color);
            }
    }

    .finder .icon.search-trigger {
        color: $white;
        background-color: $blue;
    }

    .navbar-brand .navbar-burger {
        background-color: $blue-dark;
        color: $white;
    }

    @include desktop {
        .navbar-item {
            p {
                margin-block: 1.3rem;
                color: var(--color-office);
            }

            span.item-text {
                display: none;
            }
        }
        .office-menu .navbar-dropdown.navbar-dropdown-first .dropdown-style {
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }

        .current-office {
            .navbar-item {
                p {
                    color: $white;
                }
            }
            &.office-title {
                background-color: var(--color);
                color: $white;
    
                &:hover, &.fixed-title  {
                    background-color: var(--color);
                    p, span {
                        color: $white;
                    }
                }
    
                .dropdown-style,
                .dropdown-style .office-item {
                    background-color: var(--color) !important;
                    a, span {
                        color: $white !important;
                    }
                }
            }
        }
    }
}
