.block-quote {
    @extend .content; 
    @extend .is-medium; 
    @extend .has-text-black;
    margin-bottom: 1.5rem;

    blockquote {
        border-left: 0;
        color: $white;
        border-radius: 2.5rem;
        text-align: center;

        > p {
            font-weight: 700;
            font-size: 1.25rem;
            @extend .is-italic;
        }
        @include desktop {
            p {
                width: 80%;
                margin: auto;
            }
        }
    }
}