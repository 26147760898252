#wrapper {
    flex: 1;
}

.footer {

    /* .footer-icon{
        border-radius: $radius-rounded;
        align-items: flex-end;
    } */

    .menu-footer {
        .aligner {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            height: 100%;

            .menu {
                display: inline-flex;

                a {
                    margin-right: 6px;
                }
            }
        }
    }
}




