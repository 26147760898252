@font-face {
	font-family: 'Poppins-Black';
	src: local('Poppins-Black'), url('../fonts/Poppins-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Black Italic"), local("Poppins-BlackItalic"),
        url("../fonts/Poppins-BlackItalic.ttf") format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
	font-family: 'Poppins-Bold';
	src: local('Poppins-Bold'), url('../fonts/Poppins-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"),
        url("../fonts/Poppins-BoldItalic.ttf") format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Extra Bold"), local("Poppins-ExtraBold"),
        url("../fonts/Poppins-ExtraBold.ttf") format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Extra Bold Italic"), local("Poppins-ExtraBoldItalic"),
        url("../fonts/Poppins-ExtraBoldItalic.ttf") format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Extra Light"), local("Poppins-ExtraLight"),
        url("../fonts/Poppins-ExtraLight.ttf") format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Extra Light Italic"), local("Poppins-ExtraLightItalic"),
        url("../fonts/Poppins-ExtraLightItalic.ttf") format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Italic"), local("Poppins-Italic"),
        url("../fonts/Poppins-Italic.ttf") format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Light"), local("Poppins-Light"),
        url("../fonts/Poppins-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Light Italic"), local("Poppins-LightItalic"),
        url("../fonts/Poppins-LightItalic.ttf") format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Medium"), local("Poppins-Medium"),
        url("../fonts/Poppins-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"),
        url("../fonts/Poppins-MediumItalic.ttf") format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins"), local("Poppins-Regular"),
        url("../fonts/Poppins-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Semi Bold"), local("Poppins-SemiBold"),
        url("../fonts/Poppins-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins Semi Bold Italic"), local("Poppins-SemiBoldItalic"),
        url("../fonts/Poppins-SemiBoldItalic.ttf") format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

